// import { withPrefix } from "gatsby"
import React from "react"
import { Component } from "react"
import PropTypes from "prop-types"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import "./gallery.css"

export default class Gallery extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      showBullets: false,
      useBrowserFullscreen: false,
      showFullscreenButton: false,
      showPlayButton: false,
      showNav: false,
      autoPlay: true,
      slideInterval: 5000,
      renderFullscreenButton: (onClick, isFullscreen) => {
        return (
          <button
            type="button"
            aria-label="Fullscreen"
            className={`image-gallery-fullscreen-button${
              isFullscreen ? " active" : ""
            }`}
            onClick={onClick}
          />
        )
      },
    }
  }

  render() {

    const { items } = this.props

    return (
      <>
     <div className="region region-content-top">
     <div id="block-views-Galleries-block_1" className="block block-views first last region-odd odd region-count-1 count-1">
         <div className="content">
           <div className="views_view view view-Galleries view-id-Galleries view-display-id-block_1 view-dom-id-1">
             <div className="view-content">
              <div id="views-nivo-slider-Galleries-block_1" className="views-nivo-slider clear-block"></div>
      <ImageGallery
        items={items}
        useBrowserFullscreen={this.state.useBrowserFullscreen}
        showPlayButton={this.state.showPlayButton}
        showFullscreenButton={this.state.showFullscreenButton}
        showNav={this.state.showNav}
        autoPlay={this.state.autoPlay}
        slideInterval={this.state.slideInterval}
                  />
                  </div>
             </div>
           </div>
         </div>

       </div>
     </>
    )
  }
}


//  export default function Gallery({ items }) {
//    return (
//      <>
//      <div className="region region-content-top">
//        <div id="block-views-Galleries-block_1" className="block block-views first last region-odd odd region-count-1 count-1">
//          <div className="content">
//            <div className="views_view view view-Galleries view-id-Galleries view-display-id-block_1 view-dom-id-1">
//              <div className="view-content">
//                <div id="views-nivo-slider-Galleries-block_1" className="views-nivo-slider clear-block">
//                  {items.map((item,index) => (
//                    <img src={withPrefix("/page-images/"+item)} key={index} alt="" title="" width="600" height="330" className="imagecache imagecache-page" />
//                  ))}

//                </div>
//              </div>
//            </div>
//          </div>

//        </div>
//      </div>
//      </>
//    )
//  }
