import React from "react"
import { Helmet } from "react-helmet"
import { Link, withPrefix } from "gatsby"

import "../styles/styles.css"
import "../styles/core.css"

export default function Layout({ children, breadcrumbs }) {
  return (
    <>
      <Helmet>
      <title>{breadcrumbs+ ' - Crockard Building Design'}</title>
      </Helmet>

      <div id="page-wrapper">
        <div id="page">
          <div id="header"><div className="section clearfix">
            <Link to="/" title="Home" id="logo"><img src={ withPrefix("/images/logo.png") } alt="Home" /></Link>
            <div className="region region-header">
              <div id="block-block-1" className="block block-block first last region-odd odd region-count-1 count-3">

                <div className="content">
                  <p><img alt="" src={ withPrefix("/images/tr.png") } /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="main-wrapper">
          <div id="main" class="clearfix">
            <div id="content" class="column">
                <div class="section">

                {children}
              </div>

            </div>
          </div>
        </div>
        </div>
        <div id="footer"><div className="section">
            <div className="region region-footer">
              <div id="block-block-2" className="block block-block first last region-odd even region-count-1 count-4">
                <div className="content">
                  <p>24 Ballyalgan Road Crossgar Downpatrick BT30 9DR</p>
                  <p className="extra">T: 028 4483 1566</p>
                </div>

            </div>
        </div>
      </div>
        </div>
      </div>


    </>
  )
}